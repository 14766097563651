import {ApolloClient, createHttpLink, InMemoryCache} from "@apollo/client";

const dexCandleGraph = () => {
    return new ApolloClient({
        link: createHttpLink({
            uri: "https://api.thegraph.com/subgraphs/name/lydiafinance/dexcandles",
        }),
        cache: new InMemoryCache(),
        // @ts-ignore: Unreachable code error
        shouldBatch: true,
    });
};

export default dexCandleGraph;