import { MenuEntry } from '@lydiafinance/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://www.lydia.finance/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    initialOpenState: true,
    items: [
      {
        label: 'Exchange',
        href: '#/swap',
      },
      {
        label: 'Liquidity',
        href: '#/pool',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'PoolIcon',
    href: 'https://www.lydia.finance/farms',
  },
  {
    label: 'Electrum Pools',
    icon: 'FarmIcon',
    href: 'https://www.lydia.finance/pools',
  },
  {
    label: 'Maximizer Farms',
    icon: 'CrownIcon',
    href: 'https://www.lydia.finance/maximizer'
  },
  {
    label: 'NFT Stake',
    icon: 'NftIcon',
    href: 'https://www.lydia.finance/nft-stake'
  },
  {
    label: 'Launchpad',
    icon: 'LaunchpadIcon',
    href: 'https://www.lydia.finance/launchpad'
  },
  {
    label: 'Lottery',
    icon: 'Lottery2',
    href: 'https://www.lydia.finance/lottery'
  },
  {
    label: 'Governance',
    icon: 'GroupsIcon',
    href: 'https://snapshot.org/#/lydiafinance.eth'
  },
  {
    label: "Buy LYD",
    icon: "CoinIcon",
    href: "https://exchange.lydia.finance",
  },
]

export default config
