import React, { useEffect, useState } from 'react'
import { Box, Flex, ButtonMenu, ButtonMenuItem, Text } from '@lydiafinance/uikit'
import { Pair, Token, TokenAmount, ChainId, WAVAX, CAVAX, Currency } from '@lydiafinance/sdk'
import useI18n from 'hooks/useI18n'
import SwapCandleStatus from 'components/SwapCandleStatus'
import SwapCandleChart from 'components/SwapCandleChart'
import {SwapWrapper, SwapControlLayout, SwapCurLayout} from 'components/SwapLayout'
import CurrencyLogo from 'components/CurrencyLogo'
import dexCandleGraph from '../../apollo/client'
import dexCandlesQuery from '../../apollo/queries'
import defaultTokenJson from '../../constants/token/lydiaswap.json'

const graph = dexCandleGraph()

enum Period {
  MIN_5 = 300,
  MIN_15 = 900,
  HOUR = 3600,
  HOUR_4 = 14400,
  DAY = 86400,
  WEEK = 604800
}

const stables = defaultTokenJson.tokens.map(x => {
  const symbol = x.symbol.toLowerCase()

  if (symbol.indexOf('usdt') > -1 || symbol.indexOf('usdc') > -1 || symbol.indexOf('dai') > -1) {
    return x.address.toLowerCase()
  }
  return null
}).filter(x => x)

const shouldReversePrice = (pair: Pair) => {
  const wavax = WAVAX[ChainId.AVALANCHE].address
  const token0Stable = stables.includes(pair.token0.address.toLowerCase())
  const token1Stable = stables.includes(pair.token1.address.toLowerCase())

  // First token avax
  if (pair.token0.address === wavax) {
    // Second token stable
    if (token1Stable) {
      return true
    }
    return false
  }

  // First token stable
  if (token0Stable) {
    if (pair.token1.address === wavax) {
      return false
    }
  }

  // Stable-Stable
  if (token0Stable && token1Stable) {
    return false
  }

  return true
}

const checkCurrency = (item) => {
  if (item) {
    const isAVAX = item.symbol?.toUpperCase() === 'AVAX'
    if (isAVAX) {
      return WAVAX[ChainId.AVALANCHE]
    }

    const isWAVAX = item.symbol?.toUpperCase() === 'WAVAX'
    if (isWAVAX) {
      return CAVAX
    }

    return item
  }
  return item
}

interface ISwapSymbols {
  pair: Pair,
  currencies: Currency[]
}

const SwapSymbols = ({ pair, currencies }: ISwapSymbols) => {
  const token0 = checkCurrency(pair.token0)
  const token1 = checkCurrency(pair.token1)

  // We need to convert token to currency in order to show a proper token image
  const currency0 = currencies.find(x => x.symbol === token0.symbol)
  const currency1 = currencies.find(x => x.symbol === token1.symbol)

  if (currency0 && currency1) {
    return <>
      <CurrencyLogo currency={currency0} size='18px' style={{ marginRight: '8px' }} />{currency0.symbol}
      <span style={{ margin: '0 8px' }}>/</span>
      <CurrencyLogo currency={currency1} size='18px' style={{ marginRight: '8px' }} />{currency1.symbol}
    </>
  }

  return null
}

interface ISwapCandle {
  currencyInput: Currency | undefined,
  currencyOutput: Currency | undefined
}

const SwapCandle = ({ currencyInput, currencyOutput }: ISwapCandle) => {
  const TranslateString = useI18n()
  const defaultLastValue = 'N/A'

  const [index, setIndex] = useState(2)
  const [loading, setLoading] = useState(true)
  const [candleData, setCandleData] = useState([] as any)
  const [lastValue, setLastValue] = useState(defaultLastValue)
  const [pair, setPair] = useState<Pair | null>(null)

  const handleClick = (newIndex: number) => setIndex(newIndex)

  useEffect(() => {
    if (!currencyInput || !currencyOutput) {
      return
    }

    const tokenInfo0 = checkCurrency(currencyInput)
    const tokenInfo1 = checkCurrency(currencyOutput)

    if(tokenInfo0 === CAVAX || tokenInfo1 === CAVAX){
      setLastValue(defaultLastValue)
      setCandleData([])
      return;
    }

    const _token0 = new Token(ChainId.AVALANCHE, tokenInfo0.address, 18, tokenInfo0.symbol)
    const _token1 = new Token(ChainId.AVALANCHE, tokenInfo1.address, 18, tokenInfo1.symbol)

    let _pair: Pair
    try {
      _pair = new Pair(new TokenAmount(_token0, '1'), new TokenAmount(_token1, '1'), ChainId.AVALANCHE)
    } catch (e) {
      return
    }

    setPair(_pair)
  }, [currencyInput, currencyOutput])

  useEffect(() => {
    if (!pair) {
      return
    }

    setLoading(true)
    const controller = new AbortController()

    const fetchGraph = async (_token0: string, _token1: string, period: Period) => {
      let candles: any[] = []
      setLastValue(defaultLastValue)

      const first = await graph.query({
        query: dexCandlesQuery,
        variables: {
          period,
          skip: 0,
          token0: _token0,
          token1: _token1
        }
      })

      if (first.data.candles.length === 0) {
        return []
      }

      const [lastCandle] = first.data.candles
      const price = shouldReversePrice(pair) ? 1 / Number(lastCandle.close) : lastCandle.close
      setLastValue(price)

      candles = [...candles, ...first.data.candles]

      if ([Period.DAY, Period.WEEK].includes(period)) {
        return candles
      }

      let range = [1000, 2000, 3000, 4000, 5000]
      if (period >= Period.HOUR) {
        range = [1000, 2000]
      }

      const promises = range.map(r => graph.query({
        query: dexCandlesQuery,
        variables: {
          period,
          skip: r,
          token0: _token0,
          token1: _token1
        }
      }))

      const responses = await Promise.all(promises)
      responses.forEach(r => {
        candles = [...candles, ...r.data.candles]
      })

      return candles.sort((a, b) => a.time - b.time)
    }

    const periods = [Period.MIN_5, Period.MIN_15, Period.HOUR, Period.HOUR_4, Period.DAY, Period.WEEK]

    setCandleData([])
    fetchGraph(pair.token0.address, pair.token1.address, periods[index]).then((resp) => {
      const data = resp.map((x) => {

        if (shouldReversePrice(pair)) {
          return {
            ...x,
            close: 1 / Number(x.close),
            high: 1 / Number(x.high),
            low: 1 / Number(x.low),
            open: 1 / Number(x.open)
          }
        }
        return {
          ...x
        }
      })

      setCandleData(data)
      setLoading(false)
    })

    // eslint-disable-next-line consistent-return
    return () => controller?.abort()
  }, [index, pair])

  return (
    <SwapWrapper>
        <Text>
          <SwapCurLayout>
            <Flex justifyContent='left' alignItems="center">
              {(pair && currencyInput && currencyOutput) &&
              <SwapSymbols pair={pair} currencies={[currencyInput, currencyOutput]} />}
            </Flex>
          </SwapCurLayout>
        </Text>
        <SwapControlLayout>
        <Text fontSize='26px'>{Number.isNaN(Number(lastValue)) ? lastValue : Number(lastValue).toFixed(10)}</Text>
            <Box mt='10px'>
              <ButtonMenu activeIndex={index} onItemClick={handleClick} scale='xs' variant='subtle'>
                <ButtonMenuItem disabled={loading}>{TranslateString(0, '5M')}</ButtonMenuItem>
                <ButtonMenuItem disabled={loading}>{TranslateString(0, '15M')}</ButtonMenuItem>
                <ButtonMenuItem disabled={loading}>{TranslateString(0, '1H')}</ButtonMenuItem>
                <ButtonMenuItem disabled={loading}>{TranslateString(0, '4H')}</ButtonMenuItem>
                <ButtonMenuItem disabled={loading}>{TranslateString(0, '1D')}</ButtonMenuItem>
                <ButtonMenuItem disabled={loading}>{TranslateString(0, '1W')}</ButtonMenuItem>
              </ButtonMenu>
            </Box>
        </SwapControlLayout>
        {loading ? <SwapCandleStatus /> : <SwapCandleChart data={candleData} />}
    </SwapWrapper>
  )
}

export default SwapCandle
