import { useEffect } from 'react'
import useGetPriceData from './useGetPriceData'

const useGetDocumentTitlePrice = () => {
  const priceData = useGetPriceData()

  const lydPriceUsd = priceData ? parseFloat(priceData?.prices?.Lyd) : 0

  const lydPriceUsdString =
    Number.isNaN(lydPriceUsd) || lydPriceUsd === 0
      ? ''
      : ` - $${lydPriceUsd.toLocaleString(undefined, {
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
        })}`

  useEffect(() => {
    document.title = `LydiaFinance${lydPriceUsdString}`
  }, [lydPriceUsdString])
}
export default useGetDocumentTitlePrice
