import React from 'react'
import { Flex, Spinner, Text } from '@lydiafinance/uikit'
import useI18n from 'hooks/useI18n'

const SwapCandleStatus = () => {
  const TranslateString = useI18n()

  return (
    <div>
      <Flex mt='50px' justifyContent='center' justifyItems='center'>
        <Text textAlign='center' fontSize='18px' color='textDisabled'>
          <Spinner size={70} /> <br />
          {TranslateString(0, 'loading...')}
        </Text>
      </Flex>
    </div>
  )
}

export default SwapCandleStatus
