import styled from "styled-components";

const SwapLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 2fr);
  grid-gap: 16px;
  grid-column: span 2;
  ${({ theme }) => theme.mediaQueries.sm} {
    grid-template-columns: repeat(1, 2fr);
    grid-gap: 24px;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-columns: repeat(1, 2fr);
    grid-gap: 24px;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    grid-template-columns: repeat(2, 2fr);
    grid-gap: 32px;
  }
`;

export const SwapWrapper = styled.div`
  position: relative;
  max-width: 436px;
  width: 100%;
  z-index: 5;

  ${({ theme }) => theme.mediaQueries.md} {
    min-width: 436px;
  };
`

export const SwapControlLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 2fr);
  grid-column: span 2;
  justify-items: center;
  ${({ theme }) => theme.mediaQueries.xs} {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-columns: repeat(2, 2fr);
    grid-gap: 24px;
    justify-items: left;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    grid-template-columns: repeat(2, 2fr);
    grid-gap: 32px;
  }
`;

export const SwapCurLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 2fr);
  justify-items: center;
  ${({ theme }) => theme.mediaQueries.xs} {
    justify-items: center;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    justify-items: center;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    justify-items: left;
  }
`;

export default SwapLayout;
