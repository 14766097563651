import React, { useEffect, useRef } from 'react'
import { createChart, ColorType, IChartApi, ISeriesApi } from 'lightweight-charts'
import { Flex, Text } from '@lydiafinance/uikit'
import useI18n from 'hooks/useI18n'

const SwapCandleChart = ({ data }) => {
  const TranslateString = useI18n()

  const chartRef = useRef(null)
  useEffect(() => {
    if (data.length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {
      }
    }

    
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const chart: IChartApi = createChart(chartRef.current!, { width: 0, height: 380 })
    const candleSeries: ISeriesApi<'Candlestick'> = chart.addCandlestickSeries()
    candleSeries.setData(data)
    candleSeries.applyOptions({
      priceFormat: {
        type: 'price',
        precision: 6,
        minMove: 0.000001
      },
      priceScaleId: 'left'
    })
    chart.applyOptions({
      layout: {
        background: { type: ColorType.Solid, color: '#FFFFFF00' },
        textColor: '#AAAAAA'
      },
      leftPriceScale: {
        visible: true
      },
      rightPriceScale: {
        visible: false
      },
      timeScale: {
        fixLeftEdge: true,
        fixRightEdge: true,
        timeVisible: true
      }
    })
    return () => {
      chart.remove()
    }
  }, [data])

  if (data.length === 0) {
    return <div>
      <Flex mt='50px' justifyContent='center' justifyItems='center'>
        <Text color="textDisabled">{TranslateString(0, 'No data for this pair')}</Text>
      </Flex>
    </div>
  }

  return <div ref={chartRef} />
}

export default SwapCandleChart
